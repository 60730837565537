var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex justify-center align-center m-background-gradient" },
    [
      _c(
        "v-card",
        { staticClass: "m-forgot-password px-1 py-4" },
        [
          _c(
            "v-row",
            {
              staticClass: "mt-4",
              attrs: { "no-gutters": "", justify: "center" },
            },
            [
              _c(
                "a",
                { attrs: { href: _vm.$store.state.siteUrl } },
                [
                  _c("v-img", {
                    attrs: {
                      contain: "",
                      src: require("@/assets/ielts/ielts-logo.svg"),
                      height: "70",
                      alt: "Fluent Logo",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                { class: { "pa-0": _vm.$vuetify.breakpoint.smAndDown } },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "", justify: "center" } },
                    [
                      _c(
                        "h3",
                        {
                          staticClass:
                            "text-md-center m-text-black mk-poppins--text mb-3",
                        },
                        [_vm._v(" Reset Password ")]
                      ),
                    ]
                  ),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "", justify: "center" } },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "text-md-center m-text-black mk-poppins--text mb-3",
                        },
                        [
                          _vm._v(
                            " Please provide the email address you used when you signed up for your Mouchak account. "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "v-form",
                    [
                      _c(
                        "v-container",
                        {
                          staticClass: "pl-lg-3 pl-sm-0 pr-lg-3 pl-sm-0",
                          attrs: { fluid: "" },
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Enter your email address",
                                  "validate-on-blur": "",
                                  "browser-autocomplete": "username",
                                  rules: [_vm.emailValidation],
                                  "prepend-icon": "email",
                                  placeholder: "johndoe@example.com",
                                },
                                nativeOn: {
                                  keypress: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    $event.preventDefault()
                                    return _vm.sendLink.apply(null, arguments)
                                  },
                                },
                                model: {
                                  value: _vm.form.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "email", $$v)
                                  },
                                  expression: "form.email",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "", justify: "center" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                loading: _vm.isLoading,
                                rounded: "",
                                color: "primary",
                              },
                              on: { click: _vm.sendLink },
                            },
                            [_vm._v("Send me the link")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }