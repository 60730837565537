<template>
  <div class="d-flex justify-center align-center m-background-gradient">
    <v-card class="m-forgot-password px-1 py-4">
      <v-row no-gutters justify="center" class="mt-4">
        <a :href="$store.state.siteUrl">
          <v-img
            contain
            :src="require('@/assets/ielts/ielts-logo.svg')"
            height="70"
            alt="Fluent Logo"
          />
        </a>
      </v-row>
      <v-card-text>
        <v-container :class="{ 'pa-0': $vuetify.breakpoint.smAndDown }">
          <v-row no-gutters justify="center">
            <h3 class="text-md-center m-text-black mk-poppins--text mb-3">
              Reset Password
            </h3>
          </v-row>
          <v-row no-gutters justify="center">
            <p class="text-md-center m-text-black mk-poppins--text mb-3">
              Please provide the email address you used when you signed up for
              your Mouchak account.
            </p>
          </v-row>
          <v-form>
            <v-container fluid class="pl-lg-3 pl-sm-0 pr-lg-3 pl-sm-0">
              <v-row no-gutters>
                <v-text-field
                  label="Enter your email address"
                  validate-on-blur
                  browser-autocomplete="username"
                  :rules="[emailValidation]"
                  :prepend-icon="'email'"
                  placeholder="johndoe@example.com"
                  @keypress.native.enter.prevent="sendLink"
                  v-model="form.email"
                >
                </v-text-field>
              </v-row>
            </v-container>
            <v-row no-gutters justify="center">
              <v-btn
                @click="sendLink"
                :loading="isLoading"
                rounded
                color="primary"
                >Send me the link</v-btn
              >
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import authService from "../services/AuthService";
import { FieldValidations } from "/global/utils/validations.js";

export default {
  name: "MForgotPassword",
  mixins: [FieldValidations],
  data() {
    return {
      form: {
        email: ""
      },
      isLoading: false,
    };
  },
  methods: {
    async sendLink() {
      this.isLoading = true;
      try {
        await authService.sendPasswordResetLink(this.form.email);
        this.$root.$emit("alert", ["Success", "Your email has been sent."]);
      } catch (e) {
        this.$root.$emit("alert", [
          "Info",
          "User with this email not found or user use google login"
        ]);
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.m-forgot-password {
  width: 500px !important;
  border: 1px solid #dadce0 !important;
  border-radius: 20px !important;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
  min-height: 400px;

  @media (max-width: 600px) {
    min-width: 100vw;
    height: 100vh;
  }
  .v-card-title {
    padding-bottom: 5px;
  }
  .v-input--checkbox {
    .v-label {
      margin-bottom: 0;
    }
  }
}
</style>
